<template>
    <div v-loading="dataLoading" v-if="formData" class="tl">
        <vxe-toolbar class="tl-header">
            <template v-slot:buttons>
                <div>
                    <div v-if="pageTitle && !noTitle">
                        <div class="tl-header-title">
                            <div>{{pageTitle}}</div>
                            <el-tooltip class="item" effect="light" :content="addButtonName" placement="bottom"  v-if="access.add && addButtonName">
                                <el-button type="text" @click="insertEvent" v-if="access.add">
                                    <svg-icon icon-class="plus" />
                                </el-button>
                            </el-tooltip>
                        </div>
                        <div class="tl-header-breadcrumbs" v-if="pageBreadcrumbs.length">
                            <el-breadcrumb>
                                <el-breadcrumb-item v-for="(item,index)  in pageBreadcrumbs" v-if="item.meta.title" :key="item.path">
                                    {{ item.meta.pagetitle || item.meta.title }}
                                </el-breadcrumb-item>
                            </el-breadcrumb>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:tools>
                <template v-if="exportConfig && access.view">
                    <ExcelExport :module="module"></ExcelExport>
                </template>
                <template v-if="importConfig && access.edit">
                    <ExcelImport :modal-width="modalWidth" :module="module">
                        <template v-slot:import_tpl>
                            <slot name="import_tpl"></slot>
                        </template>
                    </ExcelImport>
                </template>

                <el-popover
                    placement="bottom"
                    width="300"
                    trigger="click"
                    @show="getColumnsConfig"
                    v-if="!noColConf"
                    popper-class="tl-config-popover">

                    <div>
                        <draggable
                            :list="columnsConfig"
                            class="tl-config-list-group"
                            ghost-class="tl-config-ghost">
                            <div
                                class="tl-config-list-group-item"
                                v-for="col in columnsConfig"
                                :key="col.field"
                                v-if="col.title">
                                <el-checkbox v-model="col.active"></el-checkbox> {{ col.title }}
                            </div>
                        </draggable>
                        <div class="tl-config-list-buttons">
                            <el-button @click="restoreColumns" size="small">Restore</el-button>
                            <div class="spacer" />
                            <el-button type="primary" @click="storeColumns" size="small">Confirm</el-button>
                        </div>
                    </div>

                    <el-tooltip class="item" content="Config Table Columns" placement="bottom" size="mini"  slot="reference">
                        <el-button type="text" size="mini" v-if="access.view" style="margin-left:10px;">
                            <svg-icon icon-class="filter" class="tl-header-title-columns-icon" />
                        </el-button>
                    </el-tooltip>
                </el-popover>
            </template>
        </vxe-toolbar>

        <vxe-grid
            :round="gridBorderRound"
            stripe
            border="outer"
            :max-height="tableHeight"
            :columns="gridColumns"
            :data="tableData"
            show-overflow
            highlight-hover-row
            highlight-current-row
            size="mini"
            class="tl-grid"
            header-row-class-name="tl-grid-header"
            @page-change="handlePageChange"
            @cell-dblclick="cellDBLClickEvent"
            @cell-click="cellClick"
            :sortConfig="sortConfig"
            ref="DataGrid"
            auto-resize
            :seq-config="seqConfig"
            :scroll-y="{enable: false, gt: -1}">

            <template v-slot:col_header="{ column }">
                <div class="tl-grid-header-title-wrapper"  @click="sortTable(column)">
                    <div v-if="column.sortable" class="tl-grid-header-sort">
                        <div class="spacer" />
                        <div class="tl-grid-header-sort-top" v-bind:class="{ active: column.order === 'asc' }"><i class="el-icon-caret-top"></i></div>
                        <div class="tl-grid-header-sort-bottom" v-bind:class="{ active: column.order === 'desc' }"><i class="el-icon-caret-bottom"></i></div>
                        <div class="spacer" />
                    </div>
                    <div class="tl-grid-header-title">{{column.title}}</div>
                </div>

                <template v-if="Object.keys(filters).length">
                    <div v-if="column.params && column.params.filter" class="tl-grid-header-filter">
                        <template v-if="column.params.filter === 'date' && filters[column.property]">
                            <el-date-picker
                                type="date"
                                size="mini"
                                v-model="filters[column.property].value"
                                :format="'yyyy-MM-dd'"
                                :value-format="'yyyy-MM-dd'"
                                @click.native.prevent="stopPropagation"
                                @input.native.prevent="stopPropagation"
                                @change="applyFilters"
                            >
                            </el-date-picker>
                        </template>
                        <template v-if="column.params.filter === 'input' && filters[column.property]">
                            <el-input
                                size="mini"
                                clearable
                                v-model="filters[column.property].value"
                                @click.native.prevent="stopPropagation"
                                @input.native.prevent="stopPropagation"
                                @change="applyFilters" />
                        </template>
                        <template v-if="column.params.filter === 'boolean' && filters[column.property]">
                            <el-select
                                size="mini"
                                clearable
                                placeholder=""
                                v-model="filters[column.property].value"
                                @click.native.prevent="stopPropagation"
                                @input.native.prevent="stopPropagation"
                                @change="applyFilters">
                                <el-option label="yes" value="true" />
                                <el-option label="no" value="false" />
                            </el-select>
                        </template>
                        <template v-if="(column.params.filter === 'select' || column.params.filter === 'select_array') && filters[column.property] && column.params.options && column.params.options.length">
                            <el-select
                                size="mini"
                                clearable
                                placeholder=""
                                v-model="filters[column.property].value"
                                @click.native.prevent="stopPropagation"
                                @input.native.prevent="stopPropagation"
                                @change="applyFilters">
                                <el-option
                                    v-for="item in column.params.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </template>
                    </div>
                    <div v-else class="tl-grid-header-filter">
                    </div>
                </template>
            </template>

            <template v-slot:oper_default="{ row, column }">
                <slot name="additional_oper_buttons" v-bind:row="row"></slot>

                <el-button
                    type="text"
                    class="tl-edit_button"
                    @click="editEvent(row)"
                    size="mini"
                    v-if="access.edit && !noTableEdit">
                    <svg-icon icon-class="edit" />
                </el-button>
                <el-button
                    type="text"
                    class="tl-del_button"
                    @click="removeEvent(row)"
                    size="mini"
                    v-if="access.del && !noTableDel">
                    <svg-icon icon-class="delete" />
                </el-button>
            </template>

            <template v-slot:checkbox_default="{ row, column }">
                <el-checkbox
                    v-model="row[column.property]"
                    :disabled="!access.edit"
                    @change="confirmCheckboxInline(row)" />
            </template>

            <template v-slot:select_default="{ row, column }">
                <EditableCell
                    :record="row"
                    :field="column.property"
                    :display-value="findSelectTemplateValue(row, column)"
                    :init-value="row[column.property]"
                    @update-editor="editRecordInline(row)"
                    :readonly="!access.edit"
                    :type="column.params.editor"
                    v-if="column.params && column.params.edit_inline"
                    :options="column.params.options" />
                <span v-else>{{findSelectTemplateValue(row, column)}}</span>
            </template>

            <template v-slot:editable_default="{ row, column }">
                <EditableCell
                    :record="row"
                    :field="column.property"
                    :display-value="row[column.property]"
                    @update-editor="editRecordInline(row)"
                    :readonly="!access.edit"
                    :type="column.params.editor" />
            </template>

            <template v-slot:[slot]="{ row, column }" v-for="(_, slot) in $scopedSlots">
                <slot
                    :name="slot"
                    :row="row"
                    :column="column"
                    v-if="slot !== 'form_items' && slot !== 'additional_oper_buttons'">
                </slot>
            </template>

        </vxe-grid>

        <vxe-pager
          :loading="dataLoading"
          :current-page="currentPage"
          :page-size="perPage"
          :total="totalCount"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage']"
          @page-change="handlePageChange"
          v-if="pagination">
        </vxe-pager>

        <vxe-modal
            v-model="showEdit"
            :title="current._id ? 'Edit Record' : 'Add New Record'"
            :width="modalWidth"
            resize
            destroy-on-close
            :dblclickZoom="false"
            size="mini"
            className="tl-modal">

            <el-form label-position="top" :label-width="formLabelWidth" :model="formData" size="small" ref="Editor" :rules="formRules" autocomplete="off" class="tl-form">
                <div class="tl-form-body">
                    <slot name="form_items" v-bind:formdata="formData"></slot>
                </div>

                <div class="tl-form-buttons">
                    <el-button type="danger" plain @click="removeEvent(formData)" v-if="formData._id">Delete</el-button>
                    <div class="spacer" />
                    <el-button @click="showEdit = false">Cancel</el-button>
                    <el-button type="primary" @click="submitEvent">Save</el-button>
                </div>
            </el-form>
        </vxe-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'
import { routerMap } from '@/router/'
import draggable from 'vuedraggable'
import {firstBy} from "thenby";
import EditableCell from "./EditableCell";
import ExcelImport from "./ExcelImport";
import ExcelExport from "./ExcelExport";

export default {
    components: {
        draggable,
        EditableCell,
        ExcelImport,
        ExcelExport,
    },
    props: {
        module: {
            type     : String,
            required : true,
        },

        formRules: {
            type     : Object,
            default  : () => {}
        },

        formInitial: {
            type     : Object,
            default  : () => {}
        },

        rights: {
            type     : Object,
            default  : {
                view : false,
                edit : false,
                add  : false,
                del  : false,
            }
        },

        tableColumns: {
            type: Array,
            default () { return [] }
        },

        initSortOrder: {
            type     : String,
            default () { return 'asc'}
        },

        initSortField: {
            type     : String,
            default () { return ''}
        },

        sortnField: {
            type     : String,
            default () { return ''}
        },

        filters: {
            type     : Object,
            default  : () => {}
        },

        addButtonName: {
            type     : String,
            default () { return ''}
        },

        formLabelWidth: {
            type     : String,
            default () { return '140px'}
        },

        height: {
            type     : Number,
        },

        title: {
            type     : String,
        },

        noOperButtons: {
            type     : Boolean,
            default () { return false }
        },
        additionalOperButtonsWidth: {
            type     : Number,
            default () { return 0 }
        },

        noColConf: {
            type     : Boolean,
            default () { return false }
        },

        noTitle: {
            type     : Boolean,
            default () { return false }
        },

        noTotal: {
            type     : Boolean,
            default () { return false }
        },

        noHeight: {
            type     : Boolean,
            default () { return false }
        },

        importConfig: {
            type: Object
        },

        exportConfig: {
            type: Object
        },

        noTableDel: {
            type     : Boolean,
            default () { return false }
        },
        noTableEdit: {
            type     : Boolean,
            default () { return false }
        },
    },

    data () {
        return {
            showEdit       : false,
            formData       : {},
            tableData      : [],
            columnsConfig  : [],
            gridColumns    : [],
        }
    },

    computed: {
        ...mapState({
            user : state => state.app.user,
        }),
        access(){
            if (this.noOperButtons) {
                return {
                    view :(this.user.full_access) ? true : this.rights.view,
                    edit : false,
                    add  : false,
                    del  : false,
                };
            }
            else if (this.user.full_access)
                return {
                    view : true,
                    edit : true,
                    add  : true,
                    del  : true,
                };
            return this.rights;
        },
        pageTitle(){
            if (this.title) return this.title;
            return (this.$route && this.$route.meta && this.$route.meta.pagetitle) ? this.$route.meta.pagetitle : undefined
        },
        pageBreadcrumbs(){
            let breadcrumbs = []
            breadcrumbs = this.getBreadcrumb(this.$route, [])
            return breadcrumbs;
        },
        dataLoading(){
            return this.$store.state[this.module].dataLoading
        },
        list(){
            return this.$store.state[this.module].list
        },
        current(){
            return this.$store.state[this.module].current
        },
        pagination() {
            return this.$store.state[this.module].pagination
        },
        currentPage: {
            get() { return this.$store.state[this.module].currentPage },
            set(value) { this.$store.commit(`${this.module}/set`, {type: 'currentPage', items:value}); },
        },
        totalCount(){
            return this.$store.state[this.module].totalCount
        },
        perPage(){
            return this.$store.state[this.module].perPage
        },
        sortCol: {
            get() { return this.$store.state[this.module].sortCol },
            set(value) { this.$store.commit(`${this.module}/set`, {type: 'sortCol', items:value}); },
        },
        sortDir: {
            get() { return this.$store.state[this.module].sortDir },
            set(value) { this.$store.commit(`${this.module}/set`, {type: 'sortDir', items:value}); },
        },
        dataFilters: {
            get() { return this.$store.state[this.module].filters },
            set(value) { this.$store.commit(`${this.module}/set`, {type: 'filters', items:value}); },
        },
        filterChanged: {
            get() { return this.$store.state[this.module].filterChanged },
            set(value) { this.$store.commit(`${this.module}/set`, {type: 'filterChanged', items:value}); },
        },


        tableHeight(){
            let h = 0;
            if (this.noHeight) { h = 0;}
            else if (this.height) { h = this.height; }
            else { 
                h = $(window).height() - 232; 
                if (this.pagination) h -= 50;
            }
            return h;
        },

        modalWidth(){
            let w
            if ($(window).width() <= 600)
                w = $(window).width()
            else if ($(window).width() > 600 && $(window).width() <= 800)
                w = $(window).width() * 0.8
            else
                w = $(window).width() * 0.5
            return w
        },

        sortConfig(){
            let config = {showIcon : false}

            if (this.initSortField) {
                config.defaultSort = {};
                config.defaultSort.field = this.initSortField;
                config.defaultSort.order = this.initSortOrder;
            }

            return config
        },
        seqConfig(){
            let config = {startIndex: (this.currentPage - 1) * this.perPage}

            if(config.startIndex < 0) config.startIndex = 0

            return config
        },

        columnsConfigStorage(){
            return this.module + 'Columns'
        },

        gridBorderRound(){
            return (this.pagination) ? false : true
        }
    },

    methods: {
        getBreadcrumb(rt, breadcrumbs){
            var $this = this
            if (rt.name !== this.$route.name) breadcrumbs.unshift(rt)
            if (rt.meta && rt.meta.parent) {
                routerMap.forEach(function(route) {
                    if (route.name === rt.meta.parent) $this.getBreadcrumb(route, breadcrumbs)
                })
            }
            return breadcrumbs
        },
        getList(){
            if (this.access.view) this.$store.dispatch(this.module + '/getList').then(() => {
                this.tableData = this.list
            })
        },
        getColumns(){
            let columns = [{ type: 'seq', width: 50, resizable: false, fixed: 'left', },]
            this.tableColumns.forEach(column => {
                let col = JSON.parse(JSON.stringify(column))
                if ('slots' in col && col.slots) {
                    col.slots.header = 'col_header'
                }
                else {
                    col.slots = { header : 'col_header' }
                }
                col.sortable = true
                if (col.params && col.params.editor && col.params.editor === 'checkbox') col.slots['default'] = 'checkbox_default';
                else if (col.params && col.params.editor && col.params.editor === 'select') col.slots['default'] = 'select_default';
                else if (col.params && col.params.editor && col.params.edit_inline) col.slots['default'] = 'editable_default';
                columns.push(col)
            })
            if ((this.access.edit || this.access.del) && !this.noOperButtons) {
                let oper_w = 50
                if (this.access.edit && this.access.del && !this.noTableDel)
                    oper_w = 80;
                if (this.additionalOperButtonsWidth) oper_w += this.additionalOperButtonsWidth;
                let oper_col = { width: oper_w, resizable: false, fixed: 'right', align:'center', slots: { default: 'oper_default' } }
                columns.push(oper_col)
            }
            this.gridColumns = JSON.parse(JSON.stringify(columns))
            this.$refs.DataGrid.reloadColumn(columns)
        },
        getColumnsConfig(){
            if (localStorage[this.columnsConfigStorage] && !this.noColConf) {
                this.columnsConfig = JSON.parse(localStorage[this.columnsConfigStorage])
                this.configureColumns();
            }
            else {
                this.getColumns();
                let columns = []
                this.gridColumns.forEach((col, index) => {
                    columns.push({
                        field  : col.field,
                        title  : col.title,
                        active : true,
                        sort   : index + 1
                    })
                })
                this.columnsConfig = columns
            }
        },
        restoreColumns(){
            localStorage.removeItem(this.columnsConfigStorage);
            this.gridColumns = JSON.parse(JSON.stringify([]))
            this.getColumns();
            this.getColumnsConfig();
        },
        storeColumns(){
            this.configureColumns();
            localStorage.setItem(this.columnsConfigStorage, JSON.stringify(this.columnsConfig));
        },
        configureColumns(){
            this.getColumns();

            this.columnsConfig.forEach((col, index) => {
                col.sort = index + 1
            })
            let fixed = {}
            this.gridColumns.forEach((col, index) => {
                col.colorder = index + 1
                if (col.fixed && col.fixed === 'left') fixed[col.colorder] = 1
            })

            this.gridColumns.forEach((col, index) => {
                if (col.field) {
                    let colConf = this.columnsConfig.find(c => c.field === col.field)
                    if (colConf) {
                        col.visible = colConf.active
                        col.colorder = colConf.sort
                    }
                }
            })
            this.gridColumns.sort(firstBy("colorder"));

            this.gridColumns.forEach((col, index) => {
                if (col.field) {
                    let iorder = index + 1
                    if (fixed[iorder])
                        this.$set(col, 'fixed', 'left');
                    else
                        this.$set(col, 'fixed', undefined);
                }
            })

            this.$refs.DataGrid.reloadColumn(this.gridColumns)
        },

        insertEvent () {
            this.showEdit = true
            this.$store.commit(this.module + '/set', {type: 'current', items:{}});
        },
        cellClick(e){
            this.$emit('cell-click', e)
        },
        cellDBLClickEvent ({ row, column }) {
            // if (this.access.edit) this.editEvent(row);
        },
        handlePageChange({ currentPage, pageSize }){
            this.$store.commit(this.module + '/set', {type: 'currentPage', items:currentPage});
            this.$store.commit(this.module + '/set', {type: 'current', items:{}});
            this.getList();
        },
        editEvent(row){
            this.showEdit = true
            this.$store.commit(this.module + '/set', {type: 'current', items:row});
        },
        removeEvent(row) {
            this.$store.commit(this.module + '/set', {type: 'current', items:row});
            this.$confirm('Record will be deleted.', 'Warning', {
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch(this.module + '/deleteItem', {id: row._id, etag: row._etag}).then(() => {
                    if(this.pagination && this.list.length === 0){
                        this.goToLastPage()
                    }
                });
                this.showEdit = false;
            }).catch(() => {});
        },
        submitEvent(){
            var $this = this;
            $this.$refs.Editor.validate((valid) => {
                if (valid) {
                    $this.$refs.Editor.clearValidate();
                    $this.showEdit = false;
                    if ($this.formData._id)
                        $this.$store.dispatch($this.module + '/updateItem', $this.formData).then(() => {
                            this.tableData = this.list
                        });
                    else {
                        $this.$store.dispatch($this.module + '/addItem', $this.formData).then(() => {
                            if($this.pagination){
                                $this.goToLastPage()
                            }
                            else{
                                this.tableData = this.list
                            }
                        });
                    }
                } else {
                    return false;
                }
            });
        },

        sortTable(column){
            if (!column.sortable) return;
            if (!this.$refs.DataGrid) return;

            if (!column.order) {
                this.$refs.DataGrid.sort(column.property, 'asc');
                this.sortCol = column.property
                this.sortDir = 'asc'
            } else if (column.order === 'desc') {
                this.$refs.DataGrid.clearSort();
                this.sortCol = this.initSortField
                this.sortDir = this.initSortOrder
            } else if (column.order === 'asc') {
                this.$refs.DataGrid.sort(column.property, 'desc');
                this.sortCol = column.property
                this.sortDir = 'desc'
            }

            this.getList()
        },
        goToLastPage(){
            let lastPage = Math.ceil(this.totalCount / this.perPage)
            this.currentPage = lastPage
            this.getList()
        },

        stopPropagation(e) {
            e.stopPropagation();
        },

        applyFilters(){
            let $this = this
            $this.dataFilters = $this.filters
            //Устанавливаем тот факт что фильтры поменялись
            $this.filterChanged = true
            $this.getList()
        },

        confirmCheckboxInline(row){
            this.$store.dispatch(this.module + '/updateItem', row).then(() => {
                this.scrollToRow(row)
            });
        },

        scrollToRow(row){
            let td = this.$refs.DataGrid.getTableData()
            if (td) {
                let data = td.visibleData
                let target_row = data.find(c => c._id === row._id)
                if (target_row) {
                    this.$refs.DataGrid.clearScroll()
                    this.$refs.DataGrid.scrollToRow(target_row)
                }
            }
        },

        findSelectTemplateValue(row, column){
            let templateValue = ''
            if (Array.isArray(row[column.property])) {
                let templateValues = []
                row[column.property].forEach(vl => {
                    let option = column.params.options.find(option => option.value === vl)
                    if (option) templateValues.push(option.label)
                })

                templateValue = templateValues.join(', ')
            }
            else {
                let option = column.params.options.find(option => option.value === row[column.property])
                if (option) templateValue = option.label;
            }
            return templateValue;
        },

        editRecordInline(row){
            this.$store.dispatch(this.module + '/updateItem', row)
        },
    },

    mounted(){
        this.getColumnsConfig();
        this.getList();
    },

    beforeDestroy() {
        //clear filters, sorting, pagination
        this.dataFilters = undefined
        this.sortCol = undefined
        this.sortDir = undefined
        this.currentPage = 1
    },

    watch: {
        gridColumns(cols, oldCols){

            if(this.filters){
                let is_columns_changed = false

                cols.map(col => {
                    oldCols.map(oldCol => {

                        if(
                            'field' in col
                            && 'field' in oldCol
                            && 'visible' in col
                            && 'visible' in oldCol
                            && col.field === oldCol.field
                            && col.visible !== oldCol.visible
                        ){
                            for(let filter_name in this.filters){
                                if(filter_name === col.field && this.filters[filter_name].value){
                                    this.filters[filter_name].value = ''
                                    is_columns_changed = true
                                }
                            }
                        }
                    })
                })

                if(is_columns_changed) this.applyFilters()
            }
        },
        showEdit(val){
            if (this.$refs.Editor) this.$refs.Editor.clearValidate();
            if (val) {
                this.formData = {};
                if (this.current._id) {
                    this.formData = Object.assign({}, this.current);
                }
                else {
                    let formDataInitial = this.formInitial || {};
                    this.formData = Object.assign({}, JSON.parse(JSON.stringify(formDataInitial)));
                }
                if (this.sortnField && !this.formData[this.sortnField]) {
                    let $this = this;
                    if ($this.list.length) {
                        let max = Math.max.apply(Math, $this.list.map(function(o) { return ($this.sortnField in o && o[$this.sortnField]) ? o[$this.sortnField] : 0; }))
                        this.formData[this.sortnField] = max + 10;
                    }

                }
            }
        },
        /*list(val){
            this.applyFilters();
        },*/
    }
}
</script>
