<template>
	<div style="margin-right: 10px">
		<el-tooltip effect="light" content="Import Data from Excel" placement="bottom" size="mini">
	        <el-button type="text" size="mini" @click="exportDialogVisible=true">
	            <svg-icon icon-class="file-import" />
	        </el-button>
	    </el-tooltip>

	    <el-dialog
			:close-on-click-modal="false"
		  	:close-on-press-escape="false"
		  	:show-close="false"
		  	append-to-body
		  	:width="dialogWidth"
		  	:visible.sync="exportDialogVisible"
		  	:custom-class="darkTheme ? 'modal-dark' : ''">

		  	<span slot="title">
		  		Import Data from Excel
		  	</span>

		  	<slot name="import_tpl"></slot>

		  	<div style="padding-top:10px; text-align:center;">
		  		<el-upload
				  	drag
				  	:action="uploadUrl"
				  	:file-list="fileList"
				  	:headers="requestHeaders"
				  	name="upload"
				  	:on-success="afterUpload"
				  	:before-upload="beforeUpload"
				  	:on-error="onUploadError">
					  	<i class="el-icon-upload"></i>
				  		<div class="el-upload__text">Select your Excel file</em></div>
				</el-upload>
	  		</div>

		  	<span slot="footer" class="dialog-footer">
			    <el-button @click="exportDialogVisible = false" size="mini">Close</el-button>
		  	</span>

		</el-dialog>
    </div>
</template>

<script>
import { mapState }  from 'vuex'

export default {
	props: {
		modalWidth: {
            type : Number,
        },
        module: {
            type     : String,
            required : true,
        },
	},

	data(){
		return {
			exportDialogVisible : false,
			fileList            : [],
			loadingInstanse     : undefined,
		}
	},

	computed: {
		...mapState({
			darkTheme : state => state.app.darkTheme,
		}),
		dialogWidth(){
			return (this.modalWidth) ? this.modalWidth.toString() : '90%'
		},
		confUrl(){
            return this.$store.getters[this.module + '/uploadUrl']
        },
		accessToken(){
            return this.$store.getters['app/accessToken']
        },
		uploadUrl(){
			return this.confUrl
		},
		requestHeaders(){
			let headers = {}
			headers['Authorization'] = 'Bearer ' + this.accessToken
			return headers
		},
	},

	methods: {
		afterUpload(){
			this.fileList = [];
			if (this.loadingInstanse) this.loadingInstanse.close();
			this.$store.dispatch(this.module + '/getList');
			this.$message({
	          	message: 'Excel file processed',
	          	type: 'success'
	        });
	        this.exportDialogVisible = false;
		},
		beforeUpload(){
			this.loadingInstanse = this.$loading({
	          	lock: true,
	          	text: 'Processing Excel file',
	          	spinner: 'el-icon-loading',
	          	background: 'rgba(0, 0, 0, 0.7)'
	        });
		},
		onUploadError(){
			if (this.loadingInstanse) this.loadingInstanse.close();
			this.$message.error('An Error Occured While Processing File');
			this.$store.dispatch(this.module + '/getList');
		}
	}
}
</script>
