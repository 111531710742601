<template>
	<div class="grid-editable-cell">
		<template v-if="!readonly">
			<el-popover
			  	placement="bottom"
			  	v-model="editorVisible"
			  	width="250">

			  		<div class="grid-editable-cell-editor-row">
			  			<div class="grid-editable-cell-editor-input">
				  			<div v-if="type==='number_input'">
				  				<el-input v-model="columnValue" size="mini" type="number" autofocus />
				  			</div>
				  			<div v-else-if="type==='input'">
				  				<el-input v-model="columnValue" size="mini" autofocus clearable />
				  			</div>
				  			<div v-else-if="type==='select'">
				  				<el-select v-model="columnValue" size="mini">
				                    <el-option
				                        v-for="item in options"
				                        :key="item.value"
				                        :label="item.label"
				                        :value="item.value">
				                    </el-option>
				                </el-select>
				  			</div>
			  			</div>

			  			<el-button size="mini" type="text" class="grid-editable-cell-editor-cancel" @click="closeEditorInline">
				        	<svg-icon icon-class="cancel"></svg-icon>
				        </el-button>

				  		<el-button size="mini" type="text" class="grid-editable-cell-editor-save" @click="confirmEditorInline">
							<svg-icon icon-class="check"></svg-icon>
						</el-button>
			  		</div>

			  	<el-button slot="reference" type="text" size="mini">
			  		{{displayValue || '---'}}
			  	</el-button>
			</el-popover>
		</template>
		<template v-else>{{displayValue}}</template>
	</div>
</template>

<script>
export default {
	props: {
		record: {
	      	type: Object,
	      	required: true,
	    },
	    field: {
      		type: String,
      		required: true,
    	},
		displayValue: {
			default  : undefined
		},
		initValue: {
			default  : undefined
		},
		readonly: {
      		type: Boolean,
      		required: false,
      		default: false
    	},
    	type: {
      		type: String,
      		required: false,
      		default: 'input'
    	},
    	options: {
    		type: Array,
      		required: false,
      		default: () => []
    	}
	},

	data(){
		return {
			editorVisible: false,
			columnValue: undefined,
		}
	},

	methods: {
		closeEditorInline(){
			this.editorVisible = false
			// this.columnValue = this.displayValue
		},
		confirmEditorInline(){
			this.record[this.field] = this.columnValue
			this.editorVisible = false
			this.$emit("update-editor", this.record);
		}
	},

	watch: {
		editorVisible(val){
			if (this.type === 'select'){
				this.columnValue = this.initValue
			}
			else {
				this.columnValue = this.displayValue
			}
		}
	}
}
</script>

<style>
.grid-editable-cell-editor-row {
	display: flex;
	align-items:center;
}
.grid-editable-cell-editor-input {
	flex-grow:1;
	margin-right: 10px;
}
.grid-editable-cell-editor-cancel, .grid-editable-cell-editor-cancel:hover {
	color: #e6c99e;
}
.grid-editable-cell-editor-save {
	/*color: green;*/
}
</style>
