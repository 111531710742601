<template>
	<div style="margin-right: 10px">
		<el-tooltip effect="light" content="Export Data to Excel" placement="bottom" size="mini">
	        <el-button type="text" size="mini" @click="download_excel">
	            <svg-icon icon-class="file-export" />
	        </el-button>
	    </el-tooltip>
	</div>
</template>

<script>
export default {
	props: {
        module: {
            type     : String,
            required : true,
        },
	},

	data(){
		return {
			loadingInstanse : undefined,
		}
	},

	methods: {
		download_excel(){
			this.loadingInstanse = this.$loading({
	          	lock: true,
	          	text: 'Processing Excel file',
	          	spinner: 'el-icon-loading',
	          	background: 'rgba(0, 0, 0, 0.7)'
	        });
    		this.$store.dispatch(this.module + '/getExcel').then(() => {
				this.loadingInstanse.close();
			});
    	}
	}
}
</script>
